.form-select {
  line-height: inherit !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}

select:focus {
  box-shadow: none !important;
}

.wrap-form.query_form span.text-input input,
.wrap-form.query_form span.text-input textarea,
.wrap-form.query_form span.text-input select {
  padding: 10px;
}

.form-message {
  color: #f7db05;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.faq-text {
  color: #091A4E;
}

.banner {
  /* height: 100vh; */
  background-image: url(../public/images/banners/web_banner.jpg);
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white
}

.banner .container {
  padding-top: 200px;
}

.bannerlogo {
  width: 291px;
  border-radius: 50%;
  
}


.banner-head {
  font-size: 30px;
  font-weight: bold;
}

.logo-content p {
  font-size: 40px;
}

.banner-content {
  padding-right: 100px !important;
}

.banner-content p:nth-child(1) {
  font-size: 40px;
  font-weight: bold;
}

.banner-content p:nth-child(2) {
  font-size: 20px;
  text-align: justify;

}
.ban-row {
  padding: 0px !important;
}

/* .banner-content p:nth-child(3) {
  font-size: 16px;
  text-align: justify;
} */

/* hover effect homepage */
.hover-effect {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-card {
  position: relative;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  padding: 20px;
}

.custom-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  color: #e8e8e8;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.custom-card .card-title {
  font-weight: 700;
  font-size: 1.25rem; 
}

.custom-card .card-text {
  line-height: 1.5;
  color: #818181;
}

.custom-card::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: linear-gradient(-45deg, #DEC504 0%, #f7db05 100%);
  z-index: -1;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.custom-card:hover::before {
  height: 100%;
}

.custom-card:hover {
  box-shadow: none;
  color: white;
}

.custom-card .card-text:hover::before {
  color: white;
}
/* footer hover */
.h3-footer{
  color: #f7db05;

}

/* .powered-by {
  position: absolute;
  bottom: 50px; 
  right: 10px; 
  z-index: 10; 

} */

/* button */
/* From Uiverse.io by alexroumi */ 
button {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
  overflow: hidden;
 }
 
 button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #f7db05;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms
 }
 
 button:hover {
  color: #e8e8e8;
 }
 
 button:hover::before {
  width: 100%;
 }

 /*  */
 .contact-bton {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
  overflow: hidden;
 }
 
 .contact-bton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #f7db05;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms
 }
 
 .contact-bton:hover {
  color: #e8e8e8;
 }
 
 .contact-bton:hover::before {
  width: 100%;
 }

 .title-header h2.pro-title {
  font-size: 56px !important;
 }






@media (max-width: 480px) {
  .bannerlogo {
    width: 100vh !important;
    border-radius: 0px !important;
  }
  /* .powered-by-chrome {
    position: initial;
    right: 20;
    
   } */
}

@media only screen and (min-width: 480px) {
  .powered-by-chrome {
    position: initial;
    right: 20px;

}
}


@media only screen and (min-width: 768px) {
  .powered-by-chrome {
    position: absolute;
    right: 20px;

}
}

@media (max-width: 767px) {
  .logo-content p {
    padding-top: 15px;
  }
  .banner-content h1 {
    font-size: 30px !important;
  } 
  
}

@media (max-width: 768px) {
  .banner {
    background-image: url(../public/images/banners/web_banner.jpg);
    height: auto;
  }

  .banner .container {
    padding-top: 80px;
  }


  .bannerlogo {
    /* width: 100vh; */
    border-radius: 50rem;
  }

  .logo-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3%;
  }

  .logo-content p {
    font-size: 28px;
  }

  .banner-content {
    padding: 10px !important;
  }

  .banner-content p:nth-child(1) {
    font-size: 21px;
  }

  .banner-content p:nth-child(2) {
    font-size: 18px;
    text-align: justify;

  }

  .nav-item a {
    color: #091A4E;
  }

}

@media (max-width: 1024px) {
  .nav-item a {
    color: #091A4E;
  }

  .digital_signage_logo {
    display: none;
  }

  .mobile_logo {
    display: inline-block;
    content: url('../public/images/logos/digital_signage_logo.png');
    width: 61px;
    margin: 5px;
    /* height: auto; */
  }
  
}

@media screen and (min-width: 767px) {
  .logo-content {
    display: block;
    text-align: center;
  }

  .owl-prev{
    display: none;
  }

  .owl-next {
    display: none;
  }
  
  
}

@media (min-width: 1025px) {
  .mobile_logo {
    display: none;
  }
}


.custom-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.owl-prev {
  position: absolute;
  z-index: 10;
  background-color: #f7db05 !important;
  color: #000000 !important;
  font-size: 20px;
  font-weight: normal !important;
  top: 160px;
  left: 0;
  width: 15px;
}

.owl-next {
  position: absolute;
  z-index: 10;
  background-color: #f7db05 !important;
  color: #000000 !important;
  font-size: 20px;
  font-weight: normal !important;
  top: 160px;
  right: 0;
  width:15px;
}
.mobile-number {
  display: none;
}


/* Only for mobile view */
@media (max-width: 768px) {
  /* Display mobile number below the logo */
  .mobile-number {
    display: block !important;
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    /* color: #333; */
  }

  /* Center the menubar on mobile view */
  .menu-bar-container {
    justify-content: center;
    width: 100%;
  }

  /* Hide the desktop logo and show the mobile logo */
  #logo-img {
    display: none;
  }

  /* Ensure the mobile logo is centered */
  .mobile_logo {
    display: block;
    /* margin: 0 auto; */
    margin: 5px 0px;
  }

  .title-header h2.pro-title {
    font-size: 35px !important;
  }
  .section-title h3.sub-title {
    font-size: 22px !important;
  }
  div.footer-add {
    margin-top: auto !important;
  }
  .faq-t {
    font-size: 18px !important;
  }
  
}


.typing-effect {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #f7db05;
  animation: typing 2s steps(10) infinite, blink 0.6s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 7ch;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
